import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import Freequote from '../components/Freequote'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Location from '../components/Location'
import { UseServicePageData } from '../components/query'
import SEO from '../components/seo'

const Services = () => {
  const pageData = UseServicePageData()
  console.log(pageData)

  const data = useStaticQuery(
    graphql`
      query {
        servicesHeader: file(relativePath: { eq: "servicesheader.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        truckLoad: file(relativePath: { eq: "truckload.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        headerTruck: file(relativePath: { eq: "header-truck.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tradeShow: file(relativePath: { eq: "trade-show.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        truckLoadSec: file(relativePath: { eq: "truckload-sec.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        crossDocking: file(relativePath: { eq: "cross-docking.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        expedite: file(relativePath: { eq: "expedite-service.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        reefer: file(relativePath: { eq: "reefer.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        flatBed: file(relativePath: { eq: "flatbed-truck.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        dryVan: file(relativePath: { eq: "dry-van.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 394, maxHeight: 219) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const servicesheader = data.servicesHeader.childImageSharp.fluid
  const truckload = data.truckLoad.childImageSharp.fluid
  const headertruck = data.headerTruck.childImageSharp.fluid
  const tradeshow = data.tradeShow.childImageSharp.fluid
  const truckloadsec = data.truckLoadSec.childImageSharp.fluid
  const crossdocking = data.crossDocking.childImageSharp.fluid
  const expedite = data.expedite.childImageSharp.fluid
  const reefer = data.reefer.childImageSharp.fluid
  const flatbed = data.flatBed.childImageSharp.fluid
  const dryvan = data.dryVan.childImageSharp.fluid

  return (
    <Layout>
      <SEO title="Services | RoadLinx Shipping Company" />
      <Hero
        image={pageData?.Services?.image?.localFile?.childImageSharp?.fluid}
        type="primary"
        title={pageData?.Services?.title}
      />
      <section className="services">
        <Container fluid>
          <Row>
            <Col className="services__col">
              <div className="services__sub-heading">
                {pageData?.Services?.heroBottom?.subHeading}
              </div>
              <div className="services__heading">
                {pageData?.Services?.heroBottom?.heading}
              </div>
            </Col>
          </Row>
          <Row>
            {pageData?.Services?.allServices.map((service, index) => {
              return (
                <Col className="services__sub-col" xl={4}>
                  <Link to={service?.url} className="services__wrapper">
                    <Img
                      fluid={
                        service?.serviceImage?.localFile?.childImageSharp?.fluid
                      }
                      className="services__sec-image"
                      alt={service?.serviceImage?.altText}
                    />
                    <div>{service?.serviceName}</div>
                  </Link>
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>
      <Freequote />
      <Location />
      <Footer />
    </Layout>
  )
}

export default Services
