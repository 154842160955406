import BackgroundImage from 'gatsby-background-image'
import React from 'react'

const Hero = props => {
  const { type, image, title, cssClass } = props
  const className = `hero ${type}`
  const customClass = cssClass ? `${type}${cssClass}` : ''

  return (
    <BackgroundImage fluid={image}>
      <section className={className}>
        <div className={`${className}__overlay`} />
        <h1 className={`hero__title ${customClass}`}>{title}</h1>
      </section>
    </BackgroundImage>
  )
}

export default Hero
